.em-c-card__flag {
	background: #00b04f !important;
}

.em-c-solid-card__body {
	background: white !important;
	color: #112 !important;
	margin-bottom: 1.2em;
}

.em-c-solid-card__body-dark {
	color: white !important;
	background: #112!important;
}

.em-c-solid-card {
	min-height: 470px;
	border: 1px solid #e5e5e5;
	border-top: 10px solid #0c69b0;
	background: inherit !important;
}

.em-c-custom-pointer:hover {
	cursor: pointer;
	border-color: #3190d9
}


.em-c-solid-card__footer {
	color: #112;
}

.em-c-solid-card__footer.content-section {
	min-height: 63%;
	padding-top: 0;
	border-bottom: 0px;
}

.button-section {
	position: sticky;
    top: 100%;
}


.content-section .em-c-definition-list {
	margin-left: 6%;
}

.content-section .em-c-definition-list__item {
	list-style: disc;
	display: list-item !important;
}