body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.em-c-alert.no-alert-actions>.em-c-alert__actions {
    display: none;
}

.em-c-text-passage {
    font-size: 15px;
}

.em-c-table-object {
    font-size: 15px;
}

p {
    margin-bottom: 1.5rem !important;
}

h1 {
    margin-bottom: 1.5rem;
}

.em-js .em-c-modal {
    background: rgba(0, 0, 0, .6);
}

.em-c-modal__window {
    border: whitesmoke 1px solid
}

.em-c-header.em-is-active+.em-c-header-overlay {
    background: rgba(0, 0, 0, .5);
}

.navLink-dark {
    color: white;
    cursor: pointer;
}

.navLink-default {
    color: var(--paletteSurface09, #545459);
    cursor: pointer!important;
}