.big-progress-bar {
	position: relative;
	width: 200px;
	margin-right: 10px;
	margin-top: 10px;
	height: 30px;
}

.big-progress-bar__line {
	position: absolute;
	width: 100%;
	height: 0;
	border: 0px;
	border-bottom: 1px solid #0c69b0;
}

.big-progress-bar__circle-with-title {
	position: absolute;
	top: -16px;
}

.big-progress-bar__circle {
	height: 30px;
	width: 30px;
	background-color: #fff;
	border-radius: 50%;
	border: 1px solid #0c69b0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}

.big-progress-bar__circle--title {
    font-size: 12px;
    width: 50px;
    margin-left: -10px;
    margin-top: 5px;
    text-align: center;
}

.em-c-field__label--margin {
    vertical-align: top;
	margin-right: 15px;
}

.big-progress-bar__title {
	margin-bottom: 17px;
}

.big-back-blue {
	background-color: #0c69b0;
}

.big-left-0 {
	left: 0%;
}

.big-left-50 {
	left: 50%;
}

.big-left-100 {
	left: 100%;
}
