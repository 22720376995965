textarea {
	resize: none;
}

textarea.resize {
	resize: both!important;
}

textarea.resize-horizontal {
	resize: horizontal!important;
}

textarea.resize-vertical {
	resize: vertical!important;
}