.progress {
    position: relative;
    width: 120px; 
    margin-right: 10px;
    margin-top: 10px;
    height: 30px;
}

.progress-bar-line {
    position: absolute;
	width: 100%;
	height: 0;
	border: 0px;
    border-bottom: 1px solid #0c69b0;
}

.circle{
    position: absolute;
    top: -6px;
    height: 13px;
    width: 13px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #0c69b0;
    display: inline-block;
}

.left-0 {
    left: 0%
}

.left-33 {
    left: 33%
}

.left-50 {
    left: 50%
}

.left-66 {
    left: 66%
}

.left-100 {
    left: 100%
}

.back-blue {
    background-color: #0c69b0;
}

.circle-yellow {
    border: 1px solid goldenrod;
    background-color: goldenrod;
}

.circle-red {
    border: 1px solid #b12a0b;
    background-color: #b12a0b;
}

.circle-green {
    border: 1px solid #00b04f;
    background-color: #00b04f;
}

.tooltip {
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 5;
}
