#sub-domain-operation-modal {
	max-height: fit-content;
	position: absolute;
}

#text-passage {
    font-weight: bold;
    display: flex;
    align-items: center;
}

#text-passage .info-icon {
	color: var(--paletteMainBase,#0c69b0);
	text-decoration: none;
	margin-right: 5px;
}

.dropdown-resource-filter-color{
    background: var(--paletteSurface02,#f5f5f5);
    color: var(--colorsBlueBase,#0c69b0);
}

.select{
    border: 1px solid var(--colorsBlueBase,#0c69b0);
}

.react-select__single-value{
    color: var(--colorsBlueBase,#0c69b0)!important;
}

.react-select__menu{
    background: var(--paletteSurface02,rgb(6, 16, 45))!important;
    color: var(--colorsBlueBase,#0c69b0)!important;
}

.react-select__menu-list{
    background: var(--paletteSurface02,rgb(6, 16, 45))!important;
    color: var(--colorsBlueBase,#0c69b0)!important;
}

.react-select__control{
    background: inherit!important;
    color: var(--colorsBlueBase,#0c69b0)!important;
    z-index: 5;
    padding: 5px;
    min-width: 200px!important;
    transition: 'all ease-in-out 2ms';
    color: var(--colorsBlueBase,#0c69b0)!important;
    border: 1px solid  var(--paletteSurface02,rgb(60, 61, 64))!important;
    /* Removes weird border around container */
    box-shadow: var(--colorsBlueBase,#0c69b0);
}

.react-select__control:hover{
    border: 1px solid  var(--colorsBlueBase,#0c69b0)!important;
    border-color: var(--colorsBlueBase,#0c69b0)!important;
}
.react-select__indicator-separator{
    background-color: inherit!important;
}

.react-select__option{
    width: max-content;
    color: var(--colorsBlueBase,#0c69b0)!important;
}

.custom-option-box {
    padding: 5px;
    transition: all ease-in-out 2ms;
    z-index: 5;
    cursor: pointer;
    border-top: 2px solid #ccc;
    border-right: 2px solid #ccc;
    color: var(--colorsBlueBase,#0c69b0)!important;
}

.custom-option-box:hover {
    background-color: var(--paletteMainBase,#0c69b0);
    color: var(--paletteSurface02,#fffefe)!important;
}

.custom-option {
    font-size: small;
    color: var(--colorsBlueBase,#0c69b0)!important;
}

.custom-option:hover {
    background-color: var(--paletteMainBase,#0c69b0);
}

.custom-option:hover {
    background-color: var(--paletteMainBase,#0c69b0);
    color: var(--paletteSurface02,#f5f5f5)!important;
}