.edit-button-bar {
	float: right;
}

section .edit-button-bar {
	float: none !important;
}

.submenu > ul.em-c-tree__list {
	display: block !important;
}

#actions-section nav.em-c-tree,
#well {
	height: 100%;
}

.container-style {
	color: var(--paletteTextBase,#112);
	position: relative;
}

.container-style::before {
	content: '';
	position: absolute;
	background-color: #0e3d83 !important;
	width: 320px;
	height: 50px;
	z-index: -1;
	top: -14px;
	left: -17px;
}

.border-color {
	border: 1px solid #0e3d83;
}

.em-c-well {
	padding-top: 0px;
}

.em-c-section {
	margin-bottom: 0px;
}

.em-c-section__header {
	margin-bottom: 0px !important;
}

.em-c-section__body {
	margin-top: 2rem;
	margin-bottom: 3rem;
}

.em-c-definition-list {
	margin-left: 0.2rem;
	display: block;
}