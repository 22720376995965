.horizontal-list-empty {
	margin: auto;
}
.horizontal-list-buttongroup {
	float: right;
}
.toolbar-righthandside {
	margin-bottom: -15px;
}
.searchbar-width {
	width: 40%;
}
.clear-filters {
	color: #0c69b0;
	text-decoration: underline;
	cursor: pointer;
}
.sort-by-p{
	margin-right: 8px;
	margin-bottom: auto !important;
	margin-top: auto !important;
}

.table__header-cell{
	text-align: center;
    font-size: .80rem;
    font-weight: 500;
}

.warning-field{
	color: var(--colorsBlueBase,#0c69b0);
	font-weight: bold;
}