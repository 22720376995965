.swagger-ui, .swagger-ui .tab li{
    color: var(--paletteActiveText,#111122);
}

.swagger-ui .topbar {
    background-color: grey;
    border-bottom: 3px solid black;
}

.hljs-attr{
    color: var(--paletteActiveText,#111122);
}

.language-json{
    /*background-color: var(--paletteActionDark,#165585);*/
    background-color: var(--palettePositiveText,#fff);

}

.microlight{
    background-color: var(--palettePositiveText,#fff) !important;
}

.content-type{
    color: var(--paletteActiveText,#111122) !important;
}

.swagger-ui .response-control-media-type--accept-controller select {
    border-color: #0c69b0;
}

.content-type-wrapper, .content-type-wrapper option{
    background-color: var(--palettePositiveText,#fff) !important;
}

.swagger-ui select{
    background: #fbfbfa;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTMuNjIxIDVoOC43NThhLjY2LjY2IDAgMDEuNTQ1IDEuMDMzbC00LjM3OSA2LjRhLjY2LjY2IDAgMDEtMS4wODkgMGwtNC4zNzktNi40QS42Ni42NiAwIDAxMy42MjEgNXoiIGZpbGw9IiMwYzY5YjAiLz48L3N2Zz4=);
    background-position-x: calc(100% - 0.7rem);
    background-position-y: 50%;
    background-size: 16px 16px;
    background-repeat: no-repeat !important;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

.response-col_status , .renderedMarkdown , .col_header, .response-col_links, .title, .nostyle, .opblock-summary-description , .parameters-container p {
    color: var(--paletteActiveText,#111122) !important;
}

 .swagger-ui .parameter__type, .swagger-ui .prop-format, .swagger-ui .parameter__in, .swagger-ui .opblock-description-wrapper p {
    color: var(--paletteActiveText,#111122) !important;
}

.em-l-container pre {
    color: var(--paletteActiveText,#111122) !important;
    background-color: transparent;
}
.version-stamp{
    color: var(--paletteActiveText,#111122) !important;
}

.parameters-col_description{
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

.parameters-col_description input, .swagger-ui input[type=text]{
    background-color: var(--palettePositiveText,#fff) !important;
    color: var(--paletteActiveText,#111122) !important;
    border-color: #0c69b0;
}

.swagger-ui .opblock.opblock-delete input[type=text]{
    border-color: #f93e3e;
}

.swagger-ui .opblock.opblock-patch input[type=text]{
    border-color: #50e3c2;
}

.swagger-ui .opblock.opblock-post input[type=text]{
    border-color: #49cc90;
}

.swagger-ui .parameter__name {
    color: var(--paletteActiveText,#111122);
}

/***Auth***/
.swagger-ui .wrapper, .swagger-ui .scheme-container{
    /*box-shadow: 0 1px 2px 0 var(--paletteActionBase,#111122);*/
    box-shadow: 0 0px 0px 0;
}

.swagger-ui .scheme-container{
    background-color: inherit;
}


.swagger-ui .arrow{
    color: var(--paletteActiveText,#111122);
}

.swagger-ui .btn.authorize{
    border: 1px solid #cccccd;
    border: 1px solid var(--paletteSurface04,#cccccd);
    background: none;
    color: #0c69b0;
    color: var(--colorsBlueBase,#0c69b0);
    font-size: 18px;
    padding: 10px 20px;
}

.swagger-ui .btn.authorize svg{
    fill: var(--colorsBlueBase,#0c69b0);
}

/********Auth modal**********/

.modal-ux .auth-container .wrapper{
    grid-template-columns: 40% 60%;
}

.modal-ux .auth-container .wrapper .renderedMarkdown p{
    font-size: 14px !important;
}

.modal-ux .auth-container .wrapper label{
    font-size: 16px;
}


.swagger-ui .dialog-ux .modal-ux {
    background-color: var(--palettePositiveText,#fff) ;
}

.swagger-ui .dialog-ux .modal-ux-header h3{
    color:var(--paletteActiveText,#111122);
}

.swagger-ui .btn.auth.btn-done{
    border: 1px solid #cccccd;
    border: 1px solid var(--paletteSurface04,#cccccd);
    background: none;
    color: #0c69b0;
    color: var(--colorsBlueBase,#0c69b0);
    font-size: 18px;
    padding: 10px 20px;
}