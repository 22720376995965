#search-block {
	text-align: center;
}

#clear-search-button {
	color: white!important;
}

#still-need-help {
	text-align: center;
	margin-bottom: 0;
}

.em-c-footer {
	margin-top: 0;
}

.em-c-tabs__body {
	border: none !important;
	border-top: 1px solid #e5e5e5;
}