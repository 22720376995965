#organizations-modal {
	max-height: fit-content;
	position: absolute;
}

#text-passage {
    font-weight: bold;
    display: flex;
    align-items: center;
}

#text-passage .info-icon {
	color: var(--paletteMainBase,#0c69b0);
	text-decoration: none;
	margin-right: 5px;
}