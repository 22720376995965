.full-width select {
	min-width: 100%;
    max-width: 100%;
}

.em-c-field.em-is-valid .em-c-field__note {
    color: var(--paletteTextBase,#112);
}

.em-c-field.em-has-error .em-c-btn {
    border: 1px solid var(--paletteNegativeBase,#b12a0b)
}

.checkbox-btn  {
	width: 100%;
    overflow: clip;
}

.em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel{
    width: auto;
}

.em-c-field.em-has-error .checkbox-btn  {
    padding: 0 0 0 calc(100% - 3rem);
    
}

.full-width select {
	min-width: 100%;
    max-width: 100%;
}