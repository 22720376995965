.toolbar-bottommargin {
    margin-bottom: 0px;
}

.edit-button-margins {
    margin-bottom: -10px;
    margin-top: -13px;
}

.table__cell-xs {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80px;
    display: inline-block;
}

.table__cell-m {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    display: inline-block;
}

.table__cell-l {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 180px;
    display: inline-block;
}

.table__cell-xl {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    display: inline-block;
}

.em-c-modal__window {
    overflow-y: scroll;
    overflow-x: hidden;
}

.em-c-modal__window::-webkit-scrollbar {
    width: 5px;
}

.em-c-modal__window::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.em-c-modal__window::-webkit-scrollbar-thumb {
    background-color: rgb(167, 167, 167);
    border-radius: 20px;
    border: none;
}

.filter-days-p {
    margin-right: 10px;
    margin-bottom: auto !important;
    margin-top: auto !important;
}

.em-c-input::-webkit-scrollbar {
    width: 5px;
}

.em-c-input::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.em-c-input::-webkit-scrollbar-thumb {
    background-color: rgb(167, 167, 167);
    border-radius: 20px;
    border: none;
}