.acordianWithoutBullets {
    list-style-type: none !important;
}

.em-c-btn--disabled {
    border-color: #e3e3e3 !important;
    color: #a5a5a5 !important;
    cursor: not-allowed !important;
}

.em-c-text-link {
    margin-right: 70px;
}

.em-c-text-link .external-link-icon {
    top: 2px;
}

.wrapper {
    display:grid;
    grid-template-columns: 90% 10%;
}

.em-c-warning-icon {
    color: #ccac00;
}

.em-c-error-icon {
    color: #b12a0b;
}


.em-c-alert-icon--medium {
    height: 1rem !important;
    width: 1rem !important;
    top: -3px !important;
}