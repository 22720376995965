.sublink-header {
	pointer-events: none;
	cursor: default;
	font-weight: bold;
	margin-top: 0.5em;
}

#header-title {
	color: inherit!important;
}

#portal-header {
	background-image: linear-gradient(45deg,var(--paletteMain,#3a397b),var(--paletteMainBase,#0c69b0))
}

#portal-header .redirect-button {
	color: var(--colorsBlueText,#fff);
	text-decoration: none;
}

#portal-header .em-l__main {
	margin-right: 5vh;
}

#portal-header .em-l__secondary {
	width: auto;
	min-width: auto;
}

.em-c-global-nav__link .em-c-switch {
	margin-top: 0px;
	display: flex;
}

.em-c-switch.em-is-active .em-c-switch {
	left: 24px;
}

.em-c-global-nav__link .em-c-switch__label {
	padding-right: 4px;
}

.em-c-global-nav__link .em-c-switch__label-name {
	color: #fff;
	font-size: .75rem;
}

.em-c-switch__toggle {
	left: 24px !important;
}

.em-c-switch__toggle:before {
    height: 16px;
    width: 16px;
    border-radius: 20px;
    position: absolute;
    top: 4px;
    z-index: 1;
}