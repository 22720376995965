.no-actions {
    padding: .75rem 1rem;
    font-family: EMprintSemibold,HelveticaNeue,Helvetica,Arial,sans-serif;
    color: #0c69b0;
    font-size: .875rem;
}

.no-actions:hover {
    cursor: not-allowed;
}

.dropdown-filter-color{
    background: var(--paletteSurface00,#fff);
    color: var(--colorsBlueBase,#0c69b0);
}

.select{
    border: 1px solid var(--colorsBlueBase,#0c69b0);
}

.searchbar-width {
	width: 40%;
}