#content {
	border: 3px solid #e5e5e5;
	height: auto;
	background-color: white;
	box-shadow: 7px 7px 5px 0px #e5e5e5;
	border-radius: 4px !important;
}

.content-body {
	padding: 0 2rem;
}
