.custom-option-box {
    padding: 5px;
    transition: all ease-in-out 2ms;
    z-index: 5;
}

.custom-option-box:hover {
    background-color: lightgray;
}

.icon-loader {
    margin-right: 10px;
}


.dropdown-importrules-filter-color{
    background: var(--paletteSurface02,#f5f5f5);
    color: var(--colorsBlueBase,#0c69b0);
}


.dropdown-importrules-control{
    background: var(--paletteSurface02,#f5f5f5);
}