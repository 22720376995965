.em-c-btn .em-c-tree__text{
	color: #0c69b0;
	font-weight: bold;
}

.em-c-btn .em-c-tree__link{
	padding: .2rem !important;
}

.em-c-btn .em-c-tree__link:hover{
	background-color: inherit;
}

.em-c-tree__list .em-c-btn{
	width: 100%;
}
.em-l-grid.em-l-grid--3up .em-c-field.em-is-disabled .em-c-select:disabled  {
	color: rgb(4, 4, 4);
}