.__react_component_tooltip::before,
.__react_component_tooltip::after {
    content: none !important; 
}

.__react_component_tooltip,
.__react_component_tooltip.show {
    background-color: #0c69b0 !important;
    opacity: 1 !important;
}

.table__cell {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80px;
    display: inline-block;
}

.table__cell-org {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 180px;
}

.table__cell-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    display: inline-block;
}

.em-c-icon_svg {
    color: var(--paletteActiveText,#112);
    display: block;
    margin: auto;
}
