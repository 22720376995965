#AppPermissionModal{
    width: 50%;
    max-height: fit-content;
    position: absolute;
    top: 0vh;
}

#AppPermissionModal .em-c-modal__body{
    padding-bottom: 0;
}

#AppPermissionModal .em-c-tabs {
    margin-bottom: 0;
}

#AppPermissionModal .em-c-tabs__panel {
    padding: 0;
}

#AppPermissionModal .em-c-tabs__body {
    padding: 0;
    border: 0;
}

#AppPermissionModal .em-c-table-object__body-inner {
    max-height: 50vh;
    overflow-y: auto;
    background: none;
}