tr ul.em-c-definition-list {
	width: 95% !important;
}

tr .em-c-toolbar {
	background: rgba(255, 255, 255, 0);
	border-bottom: none;
}

.em-c-toolbar .em-c-tree {
	margin: 0;
	height: auto !important;
	width: 100%;
}

.em-c-toolbar .em-c-tree>.em-c-tree__item:hover div.em-c-tree__link,
.em-c-toolbar .em-c-tree div.em-c-tree__link:hover
 {
	background-color: #e5e5e5;
	font-weight: bolder;
	color: #3a397b;
}


.em-c-toolbar .em-c-tree .em-c-tree__text {
	color: #0c69b0;
	font-weight: bold;
	text-decoration: underline;
}